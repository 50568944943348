import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import useSiteMetadata from './components/SiteMetadata';
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const TemplateWrapper = ({ title='WELCOME', description, children }) => {

  const metaData = useSiteMetadata();

  const siteMetadata = metaData.meta.edges[0].node.frontmatter.siteMetadata;
  const openGraphTags = metaData.meta.edges[0].node.frontmatter.openGraphTags;
  const twitterTags = metaData.meta.edges[0].node.frontmatter.twitterTags;
  const social = metaData.social.edges[0].node.frontmatter;

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no" />

        <title>{`${title} | ${siteMetadata.siteTitle}`}</title>
        <meta name="description" content={description ? description.replace(/(<([^>]+)>)/g, '') : siteMetadata.description} />

        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#764c29"/>
        <link rel="shortcut icon" href="/favicon/favicon.ico"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="msapplication-config" content="/favicon/browserconfig.xml"/>
        <meta name="theme-color" content="#ffffff"/>

        {/* OpenGraph tags */}
        <meta property="og:url" content={openGraphTags.ogurl} />
        <meta property="og:title" content={openGraphTags.ogtitle} />
        <meta property="og:description" content={openGraphTags.ogdescription} />
        <meta property="og:site_name" content={openGraphTags.ogsitename}/>
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={openGraphTags.ogimage.childImageSharp.fluid.src}
        />

        {/* Twitter tags */}
        <meta name="twitter:title" content={twitterTags.twitterTitle}/>
        <meta name="twitter:description" content={twitterTags.twitterDescription}/>
        <meta name="twitter:image" content={twitterTags.twitterImage.childImageSharp.fluid.src}/>
        <meta name="twitter:image:alt" content={twitterTags.twitterImageAlt}/>
        <meta name="twitter:card" content={twitterTags.twitterTitle}/>
        <script src="/js/element-closest.js" defer />
        <script src="/js/url-search-params.js" defer />
      </Helmet>

      <header className="nav-wrapper">
        <Navbar />
      </header>
        
        <main className="flex-grow-1">
          <div>
            <a href="https://internationalguitarcamp.com/" target="_blank"><img src="/img/igc.png" alt="IGC" /></a>
          </div>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </main>
      <div className="mt-auto">
        <Footer socialLinks={social} />
      </div>
      <ReactNotification />
    </div>
  )
};

export default TemplateWrapper;
